<template>
  <div>
    <div class="footer">
      <div class="footer__left">
        <img
          class="footer__logo"
          src="../assets/img/logo.svg"
          alt="Imagen de check para Checklist digitales."
        />
        <div class="footer__items">
          <div class="footer__item">
            <p class="foooter__title">CIUDAD</p>
            <p class="foooter__description">Villavicencio, Colombia</p>
          </div>
          <div class="footer__item">
            <p class="foooter__title">TELÉFONO</p>
            <p class="foooter__description no-bottom">313-784-0166</p>
            <p class="foooter__description">313-452-1904</p>
          </div>
          <div class="footer__item">
            <p class="foooter__title">Email</p>
            <p class="foooter__description no-bottom">ventas@qinspecting.com</p>
            <p class="foooter__description">soporte@qinspecting.com</p>
          </div>
          <div class="footer__item">
            <p class="foooter__title"><a href="politica-tratamiento-datos-personales" class="foooter__title">Política de tratamiento de datos personales</a></p>
          </div>
        </div>
        <a href="https://www.facebook.com/qinspecting" target="_blank"
          ><i class="footer__icon-social fab fa-facebook"></i
        ></a>
        <a href="https://www.instagram.com/qinspecting" target="_blank"
          ><i class="footer__icon-social fab fa-instagram"></i
        ></a>
        <a href="https://www.twitter.com/qinspecting" target="_blank"
          ><i class="footer__icon-social fab fa-twitter"></i
        ></a>
        <a href="https://www.linkedin.com/in/qinspecting" target="_blank"
          ><i class="footer__icon-social fab fa-linkedin"></i
        ></a>
        <a
          href="https://www.youtube.com/channel/UCK_z7hbi3GW6drxtlLRl3Wg"
          target="_blank"
          ><i class="footer__icon-social fab fa-youtube"></i
        ></a>
      </div>
      <div v-if="!dinamic" class="footer__center">
        <div class="footer__content-form">
          <FormFooter />
          <!-- <div v-if="dinamic" >
                        <img class="img-fluid" src="../assets/img/banner-certificados-footer.svg" alt="Imagen de validador de certificados">
                    </div> -->
        </div>
      </div>
      <div v-if="dinamic" class="footer__center footer__center--validador">
          <a href="/validador-certificados" class="footer__center-link-validador">

          </a>
          <!-- <div class="footer__content-form">

          </div> -->
      </div>
      <div class="footer__right">
        <div class="footer__right-opacity"></div>
        <div class="footer__nc">
          <h2 class="footer__h2">NUESTROS SERVICIOS</h2>
          <ul class="footer__ul">
            <li class="footer__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Checklist digitales."
              />
              <a
                href="beneficios#uno"
                class="footer__a"
                >CheckList Electrónico</a
              >
            </li>
            <li class="footer__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Gestión de personal."
              />
              <a
                href="beneficios#dos"
                class="footer__a"
                >Gestión Riesgos Operativos</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Gestión documental."
              />
              <a
                href="beneficios#tres"
                class="footer__a"
                >Gestión Documental</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Mantenimiento."
              />
              <a
                href="beneficios#cuatro"
                class="footer__a"
                >Gestión de Mantenimiento</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Informes de desempeño."
              />
              <a
                href="beneficios#nueve"
                class="footer__a"
                >Informes de Desempeño</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Cursos certificados."
              />
              <a
                href="beneficios#siete"
                class="footer__a"
                >Cursos Certificados</a
              >
            </li>

            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Cursos TireCheck."
              />
              <a
                href="beneficios#ocho"
                class="footer__a"
                >TireCheck</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Mantenimiento."
              />
              <a
                href="beneficios#cinco"
                class="footer__a "
                >Gestión Riesgos Laborales</a
              >
            </li>
            <li class="features__li">
              <img
                class="footer__check"
                src="../assets/img/check-s.svg"
                alt="Imagen de check para Informes de desempeño."
              />
              <a
                href="beneficios#seis"
                class="footer__a footer__end-item"
                >Gestión de Programas de Capacitación</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-copy">
        <p class="footer-copy__p">
          © 2020 <a href="https://appears.com.co/" target="_blank">Appears</a> .
          All rights reserved.
        </p>
      </div>
    </div>
    <a
      id="app-whatsapp"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=573137840166&amp;text=Hola!%20Estoy%20en%20la%20página%20web%20y%20quiero%20más%20información%20acerca%20de%20sus%20servicios.%20Mi%20nombre%20es..."
    >
      <img
        src="../assets/img/whatsapp.png"
        alt="Imagen de check para Checklist digitales.Imagen de Whatsapp en link de contácto"
      />
    </a>
  </div>
</template>

<script>
import FormFooter from "./FormFooter";

export default {
  name: "Footer",
  components: {
    FormFooter,
  },
  props: {
    dinamic: false,
  },
};
</script>

<style>

.footer__logo{height: 60px}

.alert-success {
  font-size: 15px;
  font-weight: 300;
}

.alert-danger {
  font-size: 15px;
  font-weight: 300;
}

.form-footer {
  display: inherit;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.error {
  color: red;
  font-size: 13px;
}

.error:focus {
  outline-color: #f99;
}
.error {
  color: var(--white);
  font-size: 13px;
}

.error:focus {
  outline-color: #f99;
}

.form-group {
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

.footer {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  border-top: 5px solid var(--primary);
}

@media screen and (max-width: 991px) {
  .footer {
    grid-template-columns: 1fr;
  }
}

.footer__left {
  padding: 50px 100px;
}

@media screen and (max-width: 1199px) {
  .footer__left {
    padding: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .footer__left {
    padding: 50px 0 50px 30px;
  }
}

@media screen and (max-width: 991px) {
  .footer__left {
    text-align: center;
    padding: 50px 0 50px 0;
  }
}

@media screen and (max-width: 320px) {
  .footer__left {
    padding: 30px 15px;
  }
}

.footer__items {
  margin: 20px 0;
}

.footer__item {
  margin: 20px 0;
}

.foooter__title {
  font-size: 20px;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0;
}

.foooter__description {
  font-size: 17px;
  font-weight: 500;
}

.no-bottom {
  margin-bottom: 0;
}

.footer__icon-social {
  margin: 20px 10px 0 0;
  font-size: 40px;
  color: var(--primary);
}

.footer__center {
  background: var(--white-secondary);
  display: grid;
}

.footer__content-form {
  align-self: center;
  justify-self: center;
  margin: 30px 0;
}

@media screen and (max-width: 320px) {
  .footer__content-form {
    margin: 30px 15px;
  }
}

.footer__form {
  width: 290px;
  padding: 30px 30px;
  background: var(--primary);
  border-radius: 20px;
  border: 2px solid var(--white);
}

@media screen and (max-width: 320px) {
  .footer__form {
    width: 225px;
  }
}

.footer__form-t {
  color: var(--white);
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
}

.footer__input {
  height: 45px;
  border-radius: 0;
  border: none;
}

.footer__submit {
  height: 45px;
  background: none;
  border: 2.5px solid var(--white);
  border-radius: 0 0 10px 10px;
  font-size: 20px;
  font-weight: 300;
}

.footer__right {
  height: 600px;
  color: var(--white);
  background-image: url("../assets/img/footer.jpg");
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.footer__nc {
  display: grid;
  justify-items: center;
  position: relative;
  z-index: 1000;
  padding: 50px 0px 50px 20px;
}

@media screen and (max-width: 1000px) {
  .footer__nc {
    padding: 50px 30px 50px 20px;
  }
}

@media screen and (max-width: 320px) {
  .footer__nc {
    padding: 50px 15px;
  }
}

.footer__ul {
  padding-left: 0;
  list-style: none;
  float: right;
}

@media screen and (max-width: 991px) {
  .footer__ul {
    float: none;
  }
}

.footer__check {
  height: 30px;
  margin-right: 5px;
}

.footer__h2 {
  font-size: 25px;
  font-weight: 500;
  float: right;
}

@media screen and (max-width: 991px) {
  .footer__h2 {
    text-align: center;
    float: none;
  }
}

.footer__a {
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 1.5em;
  color: var(--white);
  text-decoration-line: underline;
}

@media screen and (max-width: 360px) {
  .footer__a {
    font-size: 17px;
  }
}

@media screen and (max-width: 320px) {
  .footer__a {
    font-size: 15px;
  }
}

.footer__end-item {
  display: contents;
}

.footer__right-opacity {
  position: absolute;
  width: 33.33%;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.8;
}

@media screen and (max-width: 991px) {
  .footer__right-opacity {
    width: 100%;
  }
}

.footer-copy {
  grid-column: 1/4;
  text-align: center;
  color: #131313;
  background: #e4e4e4;
  z-index: 3000;
}

@media screen and (max-width: 991px) {
  .footer-copy {
    grid-column: 1/2;
  }
}

.footer-copy__p {
  margin-bottom: 0;
}

#app-whatsapp {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 110px;
  z-index: 1000;
}

@media screen {
  #app-whatsapp {
    right: 25px;
    bottom: 25px;
  }
}

#app-whatsapp img {
  width: 100%;
  height: auto;
}

.footer__center--validador {
  background-image: url("../assets/img/banner-certificados-footer.svg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 991px){
    .footer__center--validador {
        height: 300px;
    }
}

.footer__center-link-validador{
    height: 100%;
    width: 100%;
}
</style>