<template>
    <div>
        <form @submit.prevent="onSubmit" class="footer__form holder bounceIn" v-wow="{ 'animation-name': 'bounceIn','animation-duration': '2.2s'}" action="">
            <p class="footer__form-t">PREGUNTAS?</p>
            <p class="footer__form-t">CONTÁCTENOS</p>
            <div class="form-group" :class="{ 'form-group--error': $v.form.name.$error }">
                <input
                class="form-control form__input contact__input"
                type="text"
                placeholder="Nombre"
                filled
                v-model="$v.form.name.$model"
                />
            </div>
            <div v-if="$v.form.name.$model === ''">
                <div class="error" v-if="!$v.form.name.required">Dato requerido.</div>  
            </div>
            <div class="error" v-if="!$v.form.name.maxLength">Maximo {{$v.form.name.$params.maxLength.max}} carácteres.</div>

            <!-- <div class="form-group">
                <input class="footer__input form-control" type="text" placeholder="Nombre">
            </div> -->
            <div class="form-group" :class="{ 'form-group--error': $v.form.phone.$error }">
                  <input
                    class="form-control contact__input"
                    type="number"
                    placeholder="Teléfono"
                    filled
                    v-model="$v.form.phone.$model"
                  />
            </div>
            <div v-if="$v.form.phone.$model === ''">
                <div class="error" v-if="!$v.form.phone.required">Dato requerido.</div>
            </div>
            <div class="error" v-if="!$v.form.phone.minLength">Minimo {{$v.form.phone.$params.minLength.min}} numeros.</div>
            <div class="error" v-if="!$v.form.phone.maxLength">Maximo {{$v.form.phone.$params.maxLength.max}} numeros.</div>
            <div class="form-group" :class="{ 'form-group--error': $v.form.email.$error }">
              <input
                class="form-control contact__input"
                type="email"
                placeholder="Correo electrónico"
                filled
                v-model="$v.form.email.$model"
              />
            </div>
            <div v-if="$v.form.email.$model === ''">
              <div class="error" v-if="!$v.form.email.required">Dato requerido.</div>
            </div>
            <div class="error" v-if="!$v.form.email.email">Ingrese email valido.</div>
            <div class="error" v-if="!$v.form.email.maxLength">Maximo {{$v.form.email.$params.maxLength.max}} carácteres.</div>
            <!-- <div class="form-group">
                <input class="footer__input form-control" type="text" placeholder="Correo">
            </div> -->
            <div class="form-group" :class="{ 'form-group--error': $v.form.msm.$error }">   
              <textarea
                class="form-control contact__textarea"
                placeholder="Mensaje"
                filled
                v-model="$v.form.msm.$model"
              ></textarea>
            </div>
            <div v-if="$v.form.msm.$model === ''">
              <div class="error" v-if="!$v.form.msm.required">Dato requerido.</div>
            </div>
            <div class="error" v-if="!$v.form.msm.maxLength">Maximo {{$v.form.msm.$params.maxLength.max}} carácteres.</div>
            <div class="form-group">
              <button 
              class="footer__submit form-control btn btn-success" 
              @click="onSubmit"
              :disabled="submitStatus === 'PENDING'"        
              >Enviar!
              </button>
            </div>
            <!-- <div class="form-group">
                <input class="footer__submit form-control btn btn-success" type="submit" value="CONTÁCTAR">
            </div> -->
            <div v-if="!sent" class="typo__p form__success">
                <span class="col alert alert-danger form-footer" v-if="submitStatus === 'ERROR'"><i class="fal fa-times"></i> Por favor llené correctamente el formulario. </span>
            </div>
            <div v-if="!sent" class="typo__p form__success">
                <span class="col alert alert-success form-footer" v-if="submitStatus === 'PENDING'"><i class="fal fa-spinner"></i> Enviando... </span>
            </div>
            <!-- <transition name="fade"> -->
            <div v-if="sent" class="typo__p form__success">
                <span class="col alert alert-success form-footer" v-if="submitStatus === 'OK'"><i class="fal fa-check-circle"></i> Mensaje enviado con éxito! </span>
            </div>
        </form>
    </div>
</template>

<script>
import Vue from 'vue'
import { required, email, minLength, maxLength, between, submitStatus } from 'vuelidate/lib/validators'

export default {
  name: "Contacto",
  components: {
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        email: null,
        msm: null,
      },
      submitStatus: null,
      sent: false,
    };
  },
  validations: {
    form:{
      name: {required, maxLength: maxLength(60)},
      phone: {required, minLength: minLength(10), maxLength: maxLength(13)},
      email: {required, email, maxLength: maxLength(50)},
      msm: {required, maxLength: maxLength(500)},
    }
  },
  methods: {
    onSubmit() {
      // console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        // console.log("EROOR")
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        // console.log("PENDIENTE")
        setTimeout(() => {
          try {
          var url = "https://www.qinspecting.com/Qinspecting/vistas/email.php";
          var formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("phone", this.form.phone);
          formData.append("email", this.form.email);
          formData.append("msm", this.form.msm);
          // console.log(formData)
          fetch(url, {
            method: "POST",
            body: formData,
            mode: "no-cors",
            // dataType: 'multipart/form-data'
          }).then((res) => {
            this.sent = true;
            
            this.form.name = null;
            this.form.phone = null;
            this.form.email = null;
            this.form.msm = null;
            setTimeout(() => {
              this.sent = false;
              // console.log(this.sent)
            }, 2000);
          });
        } catch (e) {
          console.log(e);
        } 
          this.submitStatus = 'OK'
        }, 500)
      }
    },
  },
};
</script>
