import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import "vue2-animate/dist/vue2-animate.min.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/tirecheck",
    name: "TireCheck",
    component: () => import("../views/TireCheck"),
  },
  {
    path: "/beneficios",
    name: "Beneficios",
    component: () => import("../views/Beneficios"),
  },
  {
    path: "/planes",
    name: "Planes",
    component: () => import("../views/Planes"),
  },

  {
    path: "/contacto",
    name: "Contacto",
    component: () => import("../views/Contacto"),
  },
  {
    path: "/validador-certificados",
    name: "Validador",
    component: () => import("../views/Validador"),
  },
  {
    path: "/corte-por-facturacion",
    name: "Validador",
    component: () => import("../views/PendingBilling"),
  },
  {
    path: "/politica-tratamiento-datos-personales",
    name: "politicas",
    component: () => import("../views/privacy"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
