<template>
    <div class="support">
        <h2 class="support__h2">SOPORTE</h2>
        <div class="support__items">
            <div class="support__item">
                <img class="support__img img-fluid" src="../assets/img/support-1.svg" alt="Imagen de soporte personalizado.">
                <div class="support__box-name">
                    <h3 class="support__p">
                        Soporte Especializado
                    </h3>
                </div>
                <p class="support__description">
                    Soporte de alto nivel en español, a través de nuestro sistema de tickets.
                </p>
            </div>
            <div class="support__item">
                <img class="support__img img-fluid" src="../assets/img/support-2.svg" alt="Imagen de soporte en linea.">
                <div class="support__box-name">
                    <h3 class="support__p">
                        Chat
                    </h3>
                </div>
                <p class="support__description">
                    Nuestro personal de soporte está disponible para resolver tus dudas.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Support'
}
</script>

<style scoped>
.support{
    padding: 50px 100px;
    background: var(--white);
}

@media screen and (max-width: 1000px){
    .support{
        padding: 50px 30px;
    }
}

.support__h2{
    grid-column: 1/4;
    color: #131313;
    text-align: center;
    font-size: 50px;
    font-weight: 300;
    position: relative;
    z-index: 1000;
}

@media screen and (max-width: 800px){
  .support__h2{
      font-size: 40px;
  }
}

.support__items{
    padding: 6
    .0px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 767px) {
    .support__items{
        display: grid;
        grid-template-columns: 100%;
    }
}

.support__item{
    display: grid;
    padding: 20px 0;
}

.support__img{
    width: 170px;
    margin: auto;
}

.support__box-name{
    margin: auto;
    width: 50%;
    color: var(--white);
    background: var(--primary);
    padding: 10px 0;
}

@media screen and (max-width: 480px) {
    .support__box-name{
        width: 100%;
    }
}

.support__p{
    font-size: 17px;
    margin-bottom: 0;
    text-align: center;
}

.support__description{
    margin: auto;
    width: 50%;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .support__description{
        width: 100%;
    }
}
</style>