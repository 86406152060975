<template>
  <section id="hero">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active slider-one">
          <div class="slider">
            <div class="clip-back"></div>
            <div class="text-slider">
                <h1 class="text-slider__title">Qinspecting</h1>
                <p class="text-slider__description" itemprop="description">
                  La plataforma de administración de flotas, que mediante CheckList Electrónicos, 
                  proporciona ganancia de tiempo, reducción de costos operacionales, trazabilidad y 
                  mayor calidad en la gestión de la información, que afecta la seguridad de sus operaciones.
                </p>
                <router-link to="/contacto" class="btn btn-ghost">
                  Contáctenos
                </router-link>
                <hr class="separator" />
                <a href="/beneficios" class="btn btn-solid">
                  Más info
                </a>
              
            </div>
          </div>
        </div>
        <div class="carousel-item slider-two">
          <div class="slider">
            <div class="clip-back-r"></div>
            <div class="text-slider-r">
              <div class="text-slider-left">
              </div>
              <div class="text-slider-right">
                <p class="text-slider__title">Gestión de Riesgos Operativos</p>
                <p class="text-slider__description-r" itemprop="description">
                  Generación de información estructurada que permite la gestión de los
                  peligros que amenazan su operación, los cuales requieren de su plena
                  identificación y caracterización para el establecimiento de
                  estrategias de control y mitigación...
                </p>
                <a href="/beneficios#dos" class="btn btn-solid">
                  Más info
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item slider-three">
          <div class="slider">
            <div class="clip-back"></div>
            <div class="text-slider">
              <p class="text-slider__title">Gestión de Riesgos <span class="text-slider__salto">Laborales</span></p>
              <p class="text-slider__description" itemprop="description">
                Qinspecting contribuye a la identificación y el control de condiciones
                de trabajo desfavorables, que puedan generar la ocurrencia de
                accidentes que comprometen la integridad de las personas...
              </p>
              
             
              <a href="/beneficios#cinco" class="btn btn-solid">
                Más info
              </a>
            </div>
          </div>
        </div>
        <div class="carousel-item slider-four">
          <div class="slider">
            <div class="clip-back-r"></div>
            <div class="text-slider-r">
              <div class="text-slider-left">
              </div>
              <div class="text-slider-right">
                <p class="text-slider__title">TireCheck</p>
                <p class="text-slider__description-r" itemprop="description">
                   Módulo de apoyo al proceso de mantenimiento, diseñado especialmente
                  para las empresas de transporte terrestre, que permite una eficiente 
                  administración de las llantas, catalogádas como un insumo de alto valor 
                  económico...
                </p>
               
                <a href="/beneficios#ocho" class="btn btn-solid">
                  Más info
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item slider-five">
          <div class="slider">
            <div class="clip-back"></div>
            <div class="text-slider">
              <p class="text-slider__title">Informes de Desempeño</p>
              <p class="text-slider__description" itemprop="description">
                Qinspecting, genera informes gerenciales y detallados de desempeño,
                que contribuyen a la toma de decisiones, simplificando
                exponencialmente los procesos de su compañía...
              </p>
              
             
              <a href="/beneficios#nueve" class="btn btn-solid">
                Más info
              </a>
            </div>
          </div>
        </div>
        <div class="carousel-item slider-six">
          <div class="slider">
            <div class="clip-back-r"></div>
            <div class="text-slider-r">
              <div class="text-slider-left">
              </div>
              <div class="text-slider-right">
                <p class="text-slider__title">CheckList Electrónico</p>
                <p class="text-slider__description-r" itemprop="description">
                  Construido especialmente para comprobar de forma ordenada y
                  sistemática, el cumplimiento de los requisitos operacionales y de
                  seguridad de sus equipos, permitiendo una gestión eficaz de los
                  riesgos de su operación...
                </p>
                
                <a href="/beneficios#uno" class="btn btn-solid">
                  Más info
                </a>
              </div>
            </div>  
          </div>
        </div>
        <div class="carousel-item slider-seven">
          <div class="slider">
            <div class="clip-back"></div>
            <div class="text-slider">
              <p class="text-slider__title">Cursos Certificados</p>
              <p class="text-slider__description" itemprop="description">
               Este módulo optimiza el desempeño de los programas de capacitación y
              formación, dirigidos a validar y aumentar las competencias e
              idoneidad indispensables para desempeñar los cargos...
              </p>
              
              
              <a href="/beneficios#siete" class="btn btn-solid">
                Más info
              </a>
            </div>
          </div>
        </div>
      </div>
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="1"
          class=""
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="2"
          class=""
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="3"
          class=""
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="4"
          class=""
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="5"
          class=""
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="6"
          class=""
        ></li>
        <!-- <li data-target="#carouselExampleIndicators" data-slide-to="7" class=""></li> -->
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.slider-one {
  background-image: url("../assets/img/qinspecting_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-two {
  background-image: url("../assets/img/gestionderiesgos_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-three {
  background-image: url("../assets/img/gestionderiesgoslab_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-four {
  background-image: url("../assets/img/tirecheck_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-five {
  background-image: url("../assets/img/informesdedesempeño_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-six {
  background-image: url("../assets/img/checklistelectronico_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.slider-seven {
  background-image: url("../assets/img/cursoscertificados_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.slider {
  height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  padding-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .slider {
    height: 500px;
  }
}

@media screen and (max-width: 375px) {
  .slider {
    height: 600px;
  }
}

.clip-back {
  position: absolute;
  width: 100%;
  margin-top: 30px;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.8;
  clip-path: polygon(0 0, 65% 0, 50% 100%, 0% 100%);
}

.clip-back-r {
  position: absolute;
  width: 100%;
  margin-top: 30px;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.8;
  clip-path: polygon(100% 0%, 40% 0%, 50% 100%, 100% 100%);
}



@media screen and (max-width: 800px) {
  .clip-back {
    opacity: 0.6;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
  }
}

@media screen and (max-width: 991px) {
  .clip-back-r {
    opacity: 0.6;
    clip-path: polygon(100% 0%, 0% 0%, 10% 100%, 100% 100%);
  }
}


@media screen and (max-width: 480px) {
  .clip-back {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .clip-back-r {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.text-slider {
  color: var(--white);
  padding: 0 100px;
  z-index: 500;
}

@media screen and (max-width: 991px) {
  .text-slider {
    padding: 0 30px;
  }
}

@media screen and (max-width: 375px) {
  .text-slider {
    display: grid;
  }
}

.text-slider-r {
  color: var(--white);
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding: 0 20px;
  z-index: 500;
}

@media screen and (max-width: 991px) {
  .text-slider-r {
    grid-template-columns: 100%;
    padding: 0 30px;
  }
}

@media screen and (max-width: 375px) {
  .text-slider {
    display: grid;
  }
}

.text-slider__title {
  font-size: 65px;
  text-shadow: 2.5px 1px 0px var(--gray);
  line-height: 0.9;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .text-slider__title {
    font-size: 45px;
  }
}

@media screen and (max-width: 320px) {
  .text-slider__title {
    font-size: 35px;
  }
}


.text-slider__salto{
  display: block;
}

.text-slider__description {
  width: 50%;
  opacity: initial;
  background: none;
  font-weight: 300;
  font-size: 22px;
  text-shadow: 2.5px 1px 0px var(--gray);
}

@media screen and (max-width: 991px) {
  .text-slider__description {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .text-slider__description {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .text-slider__description {
    font-size: 17px;
  }
}

.text-slider__description-r {
  width: 100%;
  opacity: initial;
  background: none;
  font-weight: 300;
  font-size: 22px;
  text-shadow: 2.5px 1px 0px var(--gray);
}

@media screen and (max-width: 800px) {
  .text-slider__description-r {
    font-size: 17px;
  }
}

.clip-back a button {
  font-size: 20px;
}
@media screen and (max-width: 991px) {
  .clip-back {
    margin-left: 0;
  }
  .clip-back a button {
    display: flex;
    margin: auto;
  }
}

@media screen and (max-width: 360px) {
  .clip-back p {
    font-size: 30px;
  }
}
@media screen and (max-width: 320px) {
  .clip-back p {
    font-size: 20px;
  }
}

.btn {
  padding: 3px 6px;
}

.btn-ghost {
  font-size: 22px;
  color: var(--white);
  border: 2px solid var(--white);
}

.btn-ghost:hover {
  background: var(--white);
  color: var(--black);
}

.btn-solid {
  font-size: 22px;
  color: var(--white);
  background: var(--secondary);
}

.btn-solid:hover {
  background: var(--secondary);
  color: var(--white);
}

.separator {
  display: inline-block;
  align-self: center;
  height: 10px;
  width: 1px;
  margin: 0 25px;
  background: var(--white);
}

@media screen and (max-width: 480px) {
  .btn-ghost,
  .btn-solid {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .btn-ghost{
     margin-bottom: 12.5px;
     display: block;
  }

  .btn-solid {
    margin-top: 12.5px;
    display: block;
  }
  
  .separator {
    display: none;
  }
}

.carousel-indicators {
  z-index: 2000;
}

.carousel-indicators li {
  background: var(--white);
  opacity: 1;
  margin: 0 5px;
  height: 0;
  width: 20px;
  border-radius: 100%;
  cursor: pointer;
}

.carousel-indicators li.active {
  background: var(--secondary);
}
</style>
