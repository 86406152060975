<template>
    <div class="features">
        <div class="features__items-check">
            <ul class="features__ul">
                <li class="features__li">
                    <i class="features__icon fal fa-tasks"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Checklist digitales.">  -->
                    <p class="features__p">CheckList Electrónico</p>
                </li>
           
                <li class="features__li">
                    <i class="features__icon fal fa-exclamation-circle"></i> {{''}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Gestión de personal.">  -->
                    <p class="features__p">Gestión Riesgos Operativos</p>
                </li>
            
                <li class="features__li">
                    <i class="features__icon fal fa-folder"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Gestión documental.">  -->
                    <p class="features__p">Gestión Documental</p>
                </li>
                 <li class="features__li">
                     <i class="features__icon fal fa-wrench"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Mantenimiento.">  -->
                    <p class="features__p">Gestión de Mantenimiento</p>
                </li>
                <li class="features__li">
                    <i class="features__icon fal fa-shield-check"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Mantenimiento.">  -->
                    <p class="features__p">Gestión Riesgos Laborales</p>
                </li>
            </ul>
            <ul class="features__ul">
                
                <li class="features__li">
                    <i class="features__icon fal fa-chart-pie"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Informes de desempeño.">  -->
                    <p class="features__p">Informes de Desempeño</p>
                </li>
                <li class="features__li">
                    <i class="features__icon fal fa-graduation-cap"></i> {{' '}}
                    <p class="features__p">Cursos Certificados</p>
                </li>
            
                <li class="features__li">
                    <!-- <i class="features__icon fal fa-check-circle"></i> {{' '}} -->
                    <img class="features__tire" src="../assets/img/fa-tire.svg" alt="Imagen de check para Cursos certificados."> 

                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Cursos TireCheck.">  -->
                    <p class="features__p">TireCheck</p>
                </li>
                <li class="features__li">
                    <i class="features__icon fa fa-users"></i> {{' '}}
                    <!-- <img class="features__check" src="../assets/img/check.svg" alt="Imagen de check para Informes de desempeño.">  -->
                    <p class="features__p features__end-item">Gestion de Programas de Capacitación</p>
                </li>
            </ul>
            
        </div>
        <div class="features__text">
            <h2 class="features__text__h2">Carácteristicas de Qinspecting</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>

<style scoped>
    .features{
        padding: 50px 100px;
        display: grid;
        grid-template-columns: 60% 40%;
        align-items: center;
    }

    @media screen and (max-width: 1199px){
        .features{
            grid-template-columns: 100%;
        }
    }

    @media screen and (max-width: 1000px){
        .features{
            padding: 50px 30px;
        }
    }

    @media screen and (max-width: 767px){
        .features{
            grid-template-columns: 1fr;
        }
    }

    .features__ul{
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        justify-items: start;
    }

    .features__icon{
        font-size: 30px;
        font-weight: 300;
        color: var(--primary);
        width: 40px;
    }

    .features__tire{
        width: 30px;
        margin-right: 15px;
        padding-bottom: 5px;
    }

    .features__items-check{
        display: grid;
        grid-template-columns: 40% 60%;
        justify-items: center;
    } 

    @media screen and (max-width: 1500px){
        .features__items-check{
            grid-column: 1/3;
        }
    }

    @media screen and (max-width: 850px){
        .features__items-check{
            grid-template-columns: 1fr ;
            padding: 0 50px;
            justify-items: start;
        }
    }

    @media screen and (max-width: 500px){
        .features__items-check{
            padding: 0;
        }
    }
    
    .features__p{
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
    }

    @media screen and (max-width: 400px){
        .features__p{
            font-size: 17px;
        }
    }

    @media screen and (max-width: 320px){
        .features__p{
            font-size: 15px;
        }
    }
    
    .features__end-item{
        display: contents;
    }

    @media screen and (max-width: 1500px){
        .features__text{
            grid-column-start: 1; 
            grid-column-end: 4; 
            grid-row-start: 1; 
            grid-row-end: 3; 
        }
    }

    .features__text__h2{    
        margin-bottom: 0;
        font-size: 50px;
        font-weight: bold;
        text-shadow: 5px 2.5px 0px #E4E4E4;
    }

    @media screen and (max-width: 1500px){
        .features__text__h2{
            font-size: 40px;
            margin-bottom: 50px;
            text-align: center;
        }
    }

    @media screen and (max-width: 370px){
        .features__text__h2{
            font-size: 25px;
        }
    }

</style>