<template>
    <div class="video">
        <div class="video__opacity">
        </div>
        <div class="video__content" itemscope itemtype="https://schema.org/VideoGallery">
            <h2 class="video__h2" itemprop="name">Conoce Qinspecting</h2>
            <div class="video__iframe holder fadeInUp" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1s'}">
                <iframe itemprop="video" width="560" height="315" src="https://www.youtube.com/embed/e_2CVw0WVDM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import vWow from 'v-wow'
Vue.use(vWow);

export default {
    name: 'Video',
}
</script>

<style>
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    -ms-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-timing-function: ease-out;
}

.video{
    height: 500px ;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    background-image: url('../assets/img/video.jpg');
    background-image: no-repeat;
    background-image: fixed;
    background-image: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media screen and (max-width: 370px) {
    .video{
        height: 250px;
    }
}

.video__content{
    display: grid;
    z-index: 1000;
}

.video__opacity{
    position: absolute;
    width: 100%;
    height: inherit;
    background: transparent linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

.video__h2{
    text-align: center;
    color: var(--white);
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 20px;
}

@media screen and (max-width: 370px){
    .video__h2{
        font-size: 25px;
    }
}

.video__iframe{
    justify-self: center;
}

@media screen and (max-width: 630px){
    .video__iframe {
        position: relative;
        width: 95%;
        padding-bottom: 45.25%;
        height: 0;
        margin-top: 10px
    }

    .video__iframe iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}
</style>