<template>
  <div>
    <Preloader />
    <Menu />
    <Hero />
    <Features />
    <Video />
    <Adaptable />
    <ValidadorSection />
    <Numeros />
    <NuestrosClientes />
    <Support />
    <Footer />
      
  </div>
</template>

<script>
import Preloader from '@/components/Preloader'
import Menu from '@/components/Menu'
import Hero from '@/components/Hero'
import Features from '@/components/Features'
import Video from '@/components/Video'
import Adaptable from '@/components/Adaptable'
import ValidadorSection from '@/components/ValidadorSection'
import Numeros from '@/components/Numeros'
import NuestrosClientes from '@/components/NuestrosClientes'
import Support from '@/components/Support'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Preloader,
    Menu,
    Hero,
    Features,
    Video,
    Adaptable,
    ValidadorSection,
    Numeros,
    NuestrosClientes,
    Support,
    Footer,
  },
  
  metaInfo() {
    return {
      title: 'Qinspecting |',
      titleTemplate: '%s Software de Gestión de flotas | Checklist electrónicos',
      meta: [
        { 
          name: 'description', 
          content: 'Qinspecting, la plataforma de administración de flotas, que apoya la gestión de la información para la seguridad de sus operaciones.' },
        {   
          name: 'keywords',
          content: 'Qinspecting, checklist, preoperacionales, alistamientos, Transportes, Inspecciones, ChecklistElectronicos, Truck, mantenimiento, operaciones, hseq , talentohumano, gerencia, protocolodebioseguridad, protocolos, bioseguridad, Appears, Qinspecting' 
        },
        { 
          name: 'author',
          content: 'Appears' 
        },
        { 
          name: 'robots',
          content: 'Index, Follow' 
        },
        { 
          rel: "canonical", 
          href: "https://www.qinspecting.com/",
        },
        // Tags twitter
        {
          name: 'twitter:card', 
          content: 'summary',
        },
        { 
          name: 'twitter:site', 
          content: '@appears',
        },
        {
          name: 'twitter:creator',
          content: '@appears',
        },
        {
          name: 'twitter:title', 
          content: 'Qinspecting | Software de Gestión de flotas | Checklist electrónicos',    
        },
        {    
          name: 'twitter:description', 
          content: 'Qinspecting, la plataforma de administración de flotas, que apoya la gestión de la información para la seguridad de sus operaciones.',
        },
        { 
          name: 'twitter:image', 
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        // Tags facebook
        {
          property: 'og:locale',
          content: 'es_CO',
        },
        {
          property: 'og:site_name',
          content: 'Qinspecting',
        },
        {
          property: 'og:url',
          content: 'https://www.qinspecting.com',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: 'Qinspecting | Software de Gestión de flotas | Checklist electrónicos',
        },
        {
          property: 'og:description',
          content: 'Qinspecting, la plataforma de administración de flotas, que apoya la gestión de la información para la seguridad de sus operaciones.',
        },
        {
          property: 'og:image',
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        {
          property: 'og:image:secure_url',
          content: 'https://qinspecting.com/img/qinspecting.svg',
        },
        {
          property: 'og:image:width',
          content: '600',
        },
        {
          property: 'og:image:height',
          content: '323',
        }    
      ]
    }
  }
}
</script>
