<template>
	<div v-if="show" class="loading-screen">
      <div  class="loading-animation">
        <img src="../assets/img/favicon.png" alt="" class="logo">
        <div class="loading-bar"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Preloader',
  data(){  
		return {
			show: true
		}
	},
	mounted(){
		if(Boolean(this.show)){
			this.showToggle()
		}
	},
	methods: {
		showToggle(){
			setTimeout(() => {
				this.show = false
			}, 3000)
		},
	}
}
</script>

<style scoped>
.loading-screen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 20000;
}

.logo{
  width: 100px;
  height: 100px;
}

.loading-bar{
  width: 130px;
  height: 2px;
  background: #cfcfcf;
  margin-top: 22px;
  position: relative;
  overflow: hidden;
}

.loading-bar::before{
  content: "";
  width: 68px;
  height: 2px;
  background: var(--primary);
  position: absolute;
  left: -34px;
  animation: bluebar 1.5s infinite ease;
}

@keyframes bluebar {
  50%{
    left: 96px;
  }
}
</style>