<template>
  <div class="clientes">
    <div class="clientes__opacity"></div>
    <div class="cliente__content">
      <h2 class="clientes__h2">NUESTROS CLIENTES</h2>
      <div class="wrapper">
        <div class="slider">
          <div class="slide">
            <img
              v-for="logo in logos"
              :src="logo.ruta_logo"
              :alt="logo.descripcion_logo"
            />
          </div>
          <div class="slide">
            <img
              v-for="logo in logos"
              :src="logo.ruta_logo"
              :alt="logo.descripcion_logo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NuestrosClientes",
  data() {
    return {
      logos: [],
    };
  },
  mounted() {
    this.getLogosCompanies();
  },
  methods: {
    getLogosCompanies() {
      setTimeout(() => {
        try {
          axios({
            method: "get",
            url: "https://apis.qinspecting.com/newapp/select_clientes",
          })
            .then((resp) => resp.data)
            .then((logos) => {
              this.logos = logos;
            })
            .catch((error) => {
              console.log(
                "Error en la peticion para traer los nombres de las empresas"
              );
            });
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
};
</script>

<style scoped>
.clientes {
  height: 400px;
  display: grid;
  align-items: center;
  background-image: url("../assets/img/nuestro-clientes.jpg");
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.clientes__opacity {
  position: absolute;
  width: 100%;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.6;
}
.cliente__content {
  padding: 50px 100px;
}
@media screen and (max-width: 1000px) {
  .cliente__content {
    padding: 50px 30px;
  }
}
@media (max-width: 1000px) {
  .clientes__opacity {
    padding: 0 30px;
  }
}
.clientes__h2 {
  color: var(--white);
  text-align: center;
  font-size: 50px;
  font-weight: 300;
  /* text-shadow: 5px 2.5px 0px gray; */
  position: relative;
  z-index: 1000;
}
@media screen and (max-width: 800px) {
  .clientes__h2 {
    font-size: 40px;
  }
}
.wrapper {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .wrapper {
    /* margin-top: 30px; */
    height: 15vh;
  }
}
.slider {
  width: 80vw;
  height: 100px;
  position: relative;
  display: flex;
  overflow: hidden;
}
/* @media screen and (max-width: 991px){
  .slider {
    width: 100vw;
  }
} */
/* @media screen and (max-width: 400px){
  .slider {
    width: 120vw;
  }
} */
.slide {
  height: 100px;
  display: flex;
  align-items: center;
  animation: slideshow 30s linear infinite;
}
.slide img {
  height: 70px;
  padding: 0 50px;
  /* border-radius: 15%; */
}
@media screen and (max-width: 991px) {
  .slider img {
    height: 50px;
    padding: 0 25px;
  }
}
@media screen and (max-width: 480px) {
  .slider img {
    height: 40px;
    padding: 0 10px;
  }
}
@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slider::before,
.slider::after {
  height: 100px;
  width: 400px;
  position: absolute;
  content: "";
  /* background: linear-gradient(to right, var(--white) 0%, rgba(255,255,255,0) 100%); */
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
</style>
