<template>
    <div class="upgrade-platform">
        <div class="text-upgrade">
            <h2 itemprop="description">Adaptable a cualquier dispositivo</h2>
        </div>
        <div class="img-upgrade">
            <img itemprop="image" src="../assets/img/adaptable.png" class="img-upgrade__img img-fluid" alt="Plataforma adaptable a cualquier dispositivo.">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Adaptable'
}
</script>

<style scoped>

.upgrade-platform {
  margin: 100px 0;
  padding: 0 100px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  /* background: var(--white); */
}

@media (max-width: 1000px){
  .upgrade-platform{
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .upgrade-platform {
    grid-template-columns: 100%;
  }
}

/* .text-upgrade {
  padding-left: 10%;
} */
.text-upgrade h2 {
  font-size: 50px;
  font-weight: 500;
  text-shadow: 5px 2.5px 0px #E4E4E4;
  /* color: #3C5A99 */
}
/* @media screen and (max-width: 991px) {
  .text-upgrade {
    padding: 0 7%;
  }
} */
@media screen and (max-width: 767px) {
  .text-upgrade {
    margin-bottom: 20px;
  }
  .text-upgrade h2 {
    text-align: center;
    font-size: 40px;
  }
}
@media screen and (max-width: 400px) {
  .text-upgrade h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 360px) {
  .text-upgrade h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 240px) {
  .text-upgrade h2 {
    font-size: 25 px;
  }
}

.img-upgrade {
  /* padding-right: 15%; */
  justify-self: center;
}

/* @media screen and (max-width: 767px) {
  .img-upgrade {
    padding-right: 0;
  }
} */
.img-upgrade img {
  max-width: 500px;
}

@media screen and (max-width: 991px) {
  .img-upgrade img {
    max-width: 370px;
  }
}

@media screen and (max-width: 550px) {
  .img-upgrade img {
    max-width: 320px;
  }
}

@media screen and (max-width: 360px) {
  .img-upgrade img {
    max-width: 200px;
  }
}



</style>