<template>
    <div class="validador">
        <a href="/validador-certificados">
            <img class="validador__img-d img-fluid" src="../assets/img/banner-certificados.svg" alt="Imagen de validador de certificados">
            <img class="validador__img-m img-fluid" src="../assets/img/banner-certificados-m.svg" alt="Imagen de validador de certificados">
        </a>
    </div>
</template>

<script>
export default {
    name: 'ValidadorSection',
}
</script>

<style scoped>

.validador__img-d{
    width: 100%;
}

.validador__img-m{
    display: none;
}

@media screen and (max-width: 767px){
    .validador__img-d{
        display: none;
    }

    .validador__img-m{
        display: block;
    }
}

</style>