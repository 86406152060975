<template>
  <nav itemscope itemtype='http://schema.org/Organization'>
    <div class="logo">
      <a itemprop='url' href="https://qinspecting.com">
        <img src="../assets/img/logo.svg" alt="Logo de Qinspecting">
      </a>
    </div>
    <input type="checkbox" id="click">
      <label for="click" class="menu-btn">
        <i class="fal fa-bars"></i>
      </label>
    <ul itemscope itemtype='http://schema.org/SiteNavigationElement'>
      <li itemprop='name'><router-link itemprop='url' to="/beneficios">Beneficios</router-link></li>
      <li itemprop='name'><router-link itemprop='url' to="#">TireCheck</router-link></li>
      <li itemprop='name'><router-link itemprop='url' to="/planes">Planes</router-link></li>
      <li itemprop='name'><router-link itemprop='url' to="/contacto">Contáctenos</router-link></li>
      <li>
          <div class="dropdown">
            <button itemprop='name'  class="btn btn-choose dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Selecciona Empresa
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" v-for="(company, key) in empresas" itemprop='url' :href="company.url_QI" :key="key">{{company.nombre_QI}}</a>
            </div>
          </div>
      </li>
    </ul>
  </nav>      
</template>

<script>
import axios from "axios";

export default {
    name: 'Menu',
    data() {
    return {
      empresas: [],
    };
  },
  mounted(){
    this.getCompanies();
  },
  methods:{
    ActiveItem: function(){
      if(this.active == '/home'){
        one_active == 'active'
      }
    },
    getCompanies() {
      setTimeout(() => {
        try {
          axios({
            method: "get",
            url: "https://apis.qinspecting.com/newapp/select_clientes",
          })
          .then((resp) => resp.data)
          .then((companies) => {
            this.empresas = companies;                 
          })
          .catch((error) => {
            console.log("Error en la peticion para traer los nombres de las empresas");
          });
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
}
</script>

<style scoped>
nav{
  display: flex;
  height: 100px;
  width: 100%;
  background: var(--white);
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  flex-wrap: wrap;
}

nav .logo img{
  color: var(--black);
  height: 60px;
  font-weight: 600;
}

@media screen and (max-width: 360px){
  nav .logo img{
    height: 65px;
  }
}

nav ul{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
}
nav ul li{
  margin: 0 5px;
  align-self: center;
}
nav ul li a{
  color: var(--black);
  text-decoration: none;
  font-size: 17px;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

nav ul li a.router-link-exact-active{
  color: var(--primary);
  font-weight: 500;
}

.menu-btn{
  margin-bottom: 0;
}

nav .menu-btn i{
  color: var(--black);
  font-size: 30px;
  cursor: pointer;
  display: none;
}

input[type="checkbox"]{
  display: none;
}

@media (max-width: 1000px){
  nav{
    padding: 0 30px;
  }
}

@media (max-width: 1220px) {
  nav .menu-btn i{
    display: block;
  }

  #click:checked ~ .menu-btn i:before{
    content: "\f00d";
  }

  nav ul{
    position: absolute;
    top: 100px;
    left: -100%;
    z-index: 5000;
    padding-left: 0;
    background: var(--white-secondary);
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }

  #click:checked ~ ul{
    left: 0;
  }

  nav ul li{
    width: 100%;
    margin: 40px 0;
  }

  nav ul li a{
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
  }

  #click:checked ~ ul li a{
    margin-left: 0px;
  }

  nav ul li a.active,
  nav ul li a:hover{
    background: none;
    color: var(--primary);
    font-weight: bold;
  }
}

.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: -1;
  width: 100%;
  padding: 0 30px;
  color: #1b1b1b;
}
.content div{
  font-size: 40px;
  font-weight: 700;
}

.btn-choose{
  background: var(--secondary);
  color: var(--white);
  border-radius: 20px;
}

.btn-choose:hover{
  background: var(--primary);
  color: var(--white);
}

</style>